import React from 'react';
import SEO from '../../components/SEO';
import StepsHeading from '../../components/CommonSections/StepsHeading/index.jsx';
import imageIllustrationStepFirst from '../../assets/images/illustrations/shop-with-sprive-steps-first.png';
import imageIllustrationStepSecond from '../../assets/images/illustrations/shop-with-sprive-steps-second.png';
import imageIllustrationStepThird from '../../assets/images/illustrations/shop-with-sprive-steps-third.png';
import imageTestimonialsLynn from '../../assets/images/illustrations/lynn-testimonials.png';
import imageTestimonialsJames1x from '../../assets/images/illustrations/james-testimonials1x.png';
import imageTestimonialsJames2x from '../../assets/images/illustrations/james-testimonials2x.png';
import imageTestimonialsJonathan1x from '../../assets/images/illustrations/jonathan-testimonials1x.png';
import imageTestimonialsJonathan2x from '../../assets/images/illustrations/jonathan-testimonials2x.png';
import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png';
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png';
import Heading from '../../components/Heading';
import BrandsList from '../../components/BrandsList';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import useWindow from '../../hooks/useWindow';
import SliderSection from '../../components/CommonSections/SliderSection';
import DownloadAppSection from '../../components/CommonSections/DownloadAppSection';
import FAQSection from '../../components/FAQSection';
import CalculatorSection from '../../components/CommonSections/CalculatorSection';
import {
  ImageContainer,
  OutputText,
  OutputTextContainer,
} from '../../components/CommonSections/CalculatorSection/OutputComponents';
import useCalculators from '../../hooks/useCalculators';
import CustomTextField from '../../components/CommonSections/formElements/CustomTextField';
import { SecondaryDescription, SubTitleSmall } from '../../components/Typography';
import { useState } from 'react';
import { useMemo } from 'react';
import DotsLoader from '../../components/Loader';
import DownloadAppModal from '../../components/Modals/DownloadAppModal';
import ContactWidget from '../../components/ContactWidget';
import BasicModal from '../../components/Modals/BasicModal';
import { Link, navigate } from 'gatsby';
import { useEffect } from 'react';
import useSliderHeight from '../../hooks/useSliderHeight';
import useMobileNavigation from '../../hooks/useMobileNavigation';

const debounce = require('lodash.debounce');

const stepsData = [
  {
    image: imageIllustrationStepFirst,
    title: 'Step 1',
    description: (
      <>
        Download Sprive <br /> and complete sign up
      </>
    ),
  },
  {
    image: imageIllustrationStepSecond,
    title: 'Step 2',
    description: (
      <>
        Shop at your favourite brands <br /> in the Rewards tab
      </>
    ),
  },
  {
    image: imageIllustrationStepThird,
    title: 'Step 3',
    description: (
      <>
        Earn same-day cash to put <br /> towards your mortgage
      </>
    ),
  },
];

const FAQItems = [
  {
    title: 'How do I get access to Shop with Sprive?',
    description: (
      <>
        Simply download the app and complete the sign-up journey. Once done, you can find the
        feature available under the ‘Rewards’ tab.
        <br />
        <br />
        How does Shop with Sprive help homeowners?
        <br />
        <br />
        Just by doing your regular shopping with the Sprive app, you can earn extra money which you
        can send to your mortgage with just one tap. You’ll be amazed, at the impact an extra £25 a
        month towards the mortgage can have. For example, a £250,000 mortgage at 5% for 30 years
        would save you £11,230 and help you pay off your mortgage 1 year and 2 months earlier.
      </>
    ),
  },
  {
    title: 'How does Shop with Sprive work?',
    description: (
      <>
        Click on the rewards tab to become familiar with the brands we have partnered with. With
        each brand when you click on the offer you can see the % reward on offer and whether the
        offer is available in-store, online, or both. You can either pre-purchase a voucher for a
        select amount at your preferred brand or you can wait until you’ve done your normal shopping
        and you’re at checkout. Simply enter the amount you would like to purchase, hit ‘pay now’,
        and authorise the payment via your banking app. Upon successful payment, you will be
        presented with a voucher which can use to scan and pay for the shop in full. Sprive will
        then credit you the reward to your Sprive account within a couple of hours. You can then pay
        this money to your lender with just one tap.
      </>
    ),
  },
  {
    title: 'How quickly do rewards get paid to me?',
    description: (
      <>
        As soon as you’ve purchased a voucher with a brand via the Sprive app, we will aim to credit
        the reward to your Sprive account within a couple of hours at the latest.
      </>
    ),
  },
  {
    title: 'Can I use vouchers online or instore?',
    description: (
      <>
        This depends on the brand itself. Some brands are online only, some are in-store only and
        some are available in both. Before purchasing a voucher with Sprive you can check under each
        brand where the voucher can be used.
      </>
    ),
  },
  {
    title: 'How do I get access to unused vouchers?',
    description: (
      <>
        Click on the rewards tab and select ‘Your Vouchers.’ From here you can see all voucher codes
        which have not been used.
      </>
    ),
  },
  {
    title: 'What brands do you work with?',
    description: (
      <>
        We work with some of the biggest brands in the UK so that together we can help you combat
        rising prices and higher mortgage rates. We’re always working hard to add more brands, but
        here are just some of the retailers we work with: Morrisons, Waitrose, M&S, Iceland, Costa
        Coffee, Café Nero, Deliveroo, Just Eat, Uber Eats, Primark, Boohoo, Asos, Decathlon, Foot
        Locker, New Look, River Island, Superdry, John Lewis, B&M, Matalan, Argos, Boots, airbnb,
        Uber, Lastminute.com, Halfords, Ernest Jones, H.Samuel, WHSmith.
      </>
    ),
  },
  {
    title: 'Can I withdraw the money back to my bank account?',
    description: (
      <>
        All rewards earned via Shop with Sprive can only be paid towards your mortgage. The brands
        have only agreed to work with Sprive as they’re keen to help homeowners in their efforts to
        become mortgage free.
      </>
    ),
  },
  {
    title: 'Can I use Shop with Sprive if I have no extra money to make overpayments?',
    description: (
      <>
        Yes. Just indefinitely pause your auto-saves via the Auto-Save tab and then you can do your
        everyday shopping via the Rewards tab. You’ll be surprised how quickly regular use of the
        feature will help you earn. Once you have earned more than £1, you will have the ability to
        pay this to the lender with just one tap, with the exception of Nationwide as they have a
        minimum threshold of £500.
      </>
    ),
  },
  {
    title: 'Why do you not accept payments via debit/credit cards?',
    description: (
      <>
        We process payments via bank transfer so that we can offer our customers a greater % of the
        rewards. Debit card and credit card providers charge high fees which would significantly
        reduce the level of reward we would be able to offer you. Our goal is to help you save as
        much money as possible on your mortgage.
      </>
    ),
  },
  {
    title: 'Why is cashback toward your mortgage better?',
    description: (
      <>
        Cashback towards your mortgage is better using the Sprive app because it helps you save
        money on your mortgage while you do your regular shopping. Unlike other cashback sites, the
        money you earn is invested into your mortgage giving you an effective rate equal to your
        mortgage rate.
      </>
    ),
  },
  {
    title: 'Is cashback free money?',
    description: (
      <>
        Yes, as you pay the face value of the goods and then the discount is an additional benefit
        received on top of a purchase that we’ve been able to get the retailer or service provider
        to fund, so that it helps you become mortgage free, faster.
      </>
    ),
  },
  {
    title: 'What is cashback limit?',
    description: (
      <>
        Whilst there is no limit to how much cashback you can earn via Sprive, the minimum amount
        you can pay to your lender is £1 via the Sprive app.
      </>
    ),
  },
];

const assumptionsText = (
  <>
    Amount and time savings highlighted in this online calculator act as a guide, are for
    illustration purposes and may vary based on your lender. In order to create the results we've
    made the following assumptions:
    <br />
    <br />
    <ul>
      <li>Savings are calculated based on the mortgage information you have provided us</li>
      <li>Based on a repayment mortgage</li>
      <li>Overpayments will have the effect of reducing your mortgage term</li>
      <li>Interest is calculated daily and added monthly</li>
      <li>Monthly overpayments remain the same over the term of the mortgage</li>
      <li>Your rate of interest always stays the same</li>
      <li>
        No changes to your mortgage which would result in a recalculation of your monthly payment
      </li>
      <li>
        It’s based on the assumption that on average you’ll the earn the cashback reward rates
        towards your mortgage under each spending category. Savings were calculated based on the
        average available discount rates available as of today. Please note that cashback reward
        rates do change over time and we cannot accept responsibility for any discrepancies between
        the reward rates within the app and the savings highlighted within this online calculator.
      </li>
    </ul>
    <br />
    Estimations are provided on a non-advised basis. Click here to read our full{' '}
    <Link to="/terms">Terms & Conditions</Link>.
  </>
);

function ShopWithSprivePage() {
  const { isDesktop, isMobile } = useWindow();
  const [interestMortgageData, setInterestMortgageData] = useState({
    leftToPay: null,
    leftYears: null,
    monthlyPayment: null,
  });
  const [giftMortgageData, setGiftMortgageData] = useState({
    groceries: 0,
    travel: 0,
    restaurants_and_takeaway: 0,
    fashion_and_retails: 0,
    home: 0,
    electronics: 0,
  });
  const [interestCalculatorValue, setInterestCalculatorValue] = useState(null);
  const [giftCalculatorValue, setGiftCalculatorValue] = useState(null);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const sliderHeight = useSliderHeight('611px', '210px', '220px');

  const calculatorsOptions = useMemo(
    () => [
      {
        fetchUrl: 'interest-calculator',
        getBody: ({ interestPayload }) => interestPayload,
        state: interestMortgageData,
        setState: setInterestMortgageData,
        fetchResult: interestCalculatorValue,
        setFetchResult: setInterestCalculatorValue,
      },
      {
        fetchUrl: 'gift-card-calculator',
        getBody: ({ newState, interestCalculatorValue, interestPayload }) => ({
          ...interestPayload,
          total_interest: interestCalculatorValue?.data?.total_interest,
          interest_rate: interestCalculatorValue?.data?.interest_rate,
          category_amount: {
            groceries: newState.groceries,
            travel: newState.travel,
            restaurants_and_takeaway: newState.restaurants_and_takeaway,
            fashion_and_retails: newState.fashion_and_retails,
            home: newState.home,
            electronics: newState.electronics,
          },
        }),
        state: giftMortgageData,
        setState: setGiftMortgageData,
        fetchResult: giftCalculatorValue,
        setFetchResult: setGiftCalculatorValue,
      },
    ],
    [interestMortgageData, interestCalculatorValue, giftMortgageData, giftCalculatorValue]
  );

  const { handleInput, totalInterest, estimatedTimeSaving, earlyCloseSaving, loadingIndex, error } =
    useCalculators(null, calculatorsOptions);

  useEffect(() => {
    if (isMobile && firstModalOpen) {
      useMobileNavigation();
    }

    if (isMobile && isDownloadModalOpen) {
      setFirstModalOpen(true);
      setIsDownloadModalOpen(false);
    }
  }, [isMobile, isDownloadModalOpen, firstModalOpen]);

  const sliderItems = useMemo(
    () => [
      {
        image: isMobile ? imageTestimonialsJames1x : imageTestimonialsJames2x,
        description: `Great to slowly chip away at the mortgage and see the impacts in the app.
          I have found the rewards and cashback feature very easy and super quick.`,
        person: 'James Cheung',
        personInfo: 'Business Analyst',
      },
      {
        image: imageTestimonialsLynn,
        description: `Sprive has been a game changer for me. I had never overpaid my mortgage
          before Sprive, and after a year I had overpaid by £600.`,
        person: 'Lynn Beattie',
        personInfo: 'Managing Director',
      },
      {
        image: isMobile ? imageTestimonialsJonathan1x : imageTestimonialsJonathan2x,
        description: `I really like the Shop with Sprive feature where you can earn cash back the same day.
        The more you use it the more it becomes part of your everyday life and with every transaction you’re taking more off your mortgage.`,
        person: 'Jonathan Labrey',
        personInfo: 'Finance',
      },
      {
        image: isMobile ? imageTestimonialsIndi1x : imageTestimonialsIndi2x,
        description: `Thanks to Sprive I was able to both easily manage repayments,
          but also find great deals for remortgaging, which Sprive took the hassle out of too.`,
        person: 'Indi Rai',
        personInfo: 'Head of Marketing',
      },
    ],
    [isMobile]
  );

  return (
    <>
      <SEO
        title="Shop with Sprive and save thousands on cashback"
        description="Shop at your favourite brands through the app, and earn extra cash to put towards paying off your mortgage faster. Get rewarded for your everyday shopping."
      />
      <div className="common-page">
        <div className="shop-with-sprive">
          <section className="first-section">
            <StepsHeading
              title={
                <>
                  {isMobile ? (
                    <>
                      Save thousands when you shop <br /> with Sprive
                    </>
                  ) : (
                    <>
                      Save thousands on your mortgage <br /> when you shop with Sprive
                    </>
                  )}
                </>
              }
              subtitle={
                <>
                  Shop at your favourite brands through the app, and earn extra cash to put towards
                  paying off your mortgage faster. Get rewarded for your everyday shopping.
                </>
              }
              stepsData={stepsData}
              handleClick={() => setIsDownloadModalOpen(true)}
            />
          </section>
          <CalculatorSection
            title={
              <>Get started by telling us about {!isMobile && <br />} your existing mortgage</>
            }
            leftContent={
              <div className="calculator-inputs">
                <CustomTextField
                  label="£"
                  title="How much do you have left to pay on your mortgage?"
                  name="leftPay"
                  handleInput={debounce((e) => handleInput(e, 'leftToPay', 0), 300)}
                  errors={error && { leftPay: error.leftToPayError || error.invalidData }}
                />
                <CustomTextField
                  label="Years"
                  title="How many years are left on the mortgage?"
                  name="leftYears"
                  reversed={true}
                  handleInput={debounce((e) => handleInput(e, 'leftYears', 0), 300)}
                  errors={error && { leftYears: error.leftYearsError || error.invalidData }}
                />
                <CustomTextField
                  label="£"
                  title="How much is your monthly mortgage payment?"
                  name="monthlyPayment"
                  handleInput={debounce((e) => handleInput(e, 'monthlyPayment', 0), 300)}
                  errors={
                    error && { monthlyPayment: error.monthlyPaymentError || error.invalidData }
                  }
                />
                {error && error.invalidData && error.fetchError === false && (
                  <div className="error-message">
                    <SecondaryDescription>
                      Invalid amount. Please re-check the amount entered.
                    </SecondaryDescription>
                  </div>
                )}
                {error && error.fetchError === 0 && (
                  <div className="error-message">
                    <SecondaryDescription>
                      Oops, something went wrong.
                      <br />
                      Please try again later
                    </SecondaryDescription>
                  </div>
                )}
              </div>
            }
            rightContent={
              <>
                <ImageContainer>
                  <OutputText
                    title="Estimated interest you’ll pay your lender"
                    value={loadingIndex === 0 ? <DotsLoader /> : totalInterest}
                  />
                </ImageContainer>
              </>
            }
            withSeparator
            separatorMargin={'88px 0 70px'}
          />
          <section className="brands-section">
            <SectionWrapper noPadding>
              <Heading
                title={
                  <>
                    {isMobile ? (
                      <>
                        Shop with Sprive.
                        <br />
                        Save on your mortgage.
                      </>
                    ) : (
                      <>
                        Let’s see how much you could earn towards
                        <br />
                        your mortgage by shopping with Sprive.
                      </>
                    )}
                  </>
                }
                subtitle={
                  <>
                    {isMobile ? (
                      <>
                        We’ve partnered with leading brands to give you extra money when you shop
                        through the app.
                      </>
                    ) : (
                      <>
                        Here are just some of the brands we’ve partnered with to give
                        <br />
                        you extra money when you shop through the app.
                      </>
                    )}
                  </>
                }
                showOnMobile
              />
              <BrandsList withoutContainer useCustomItems />
            </SectionWrapper>
          </section>
          <CalculatorSection
            leftContent={
              <div className="calculator-inputs">
                <SubTitleSmall>
                  How much do you think you spend in each category each month?
                </SubTitleSmall>
                <div className="calculator-inputs-group">
                  <CustomTextField
                    label="£"
                    title="Groceries"
                    name="groceries"
                    handleInput={debounce((e) => handleInput(e, 'groceries', 1), 300)}
                  />
                  <CustomTextField
                    label="£"
                    title="Travel"
                    name="travel"
                    handleInput={debounce((e) => handleInput(e, 'travel', 1), 300)}
                  />
                </div>
                <div className="calculator-inputs-group">
                  <CustomTextField
                    label="£"
                    title="Takeaway"
                    name="restaurants_and_takeaway"
                    handleInput={debounce(
                      (e) => handleInput(e, 'restaurants_and_takeaway', 1),
                      300
                    )}
                  />
                  <CustomTextField
                    label="£"
                    title="Fashion/Apparel"
                    name="fashion_and_retails"
                    handleInput={debounce((e) => handleInput(e, 'fashion_and_retails', 1), 300)}
                  />
                </div>
                <div className="calculator-inputs-group">
                  <CustomTextField
                    label="£"
                    title="Home Decor"
                    name="home"
                    handleInput={debounce((e) => handleInput(e, 'home', 1), 300)}
                  />
                  <CustomTextField
                    label="£"
                    title="Electronics"
                    name="electronics"
                    handleInput={debounce((e) => handleInput(e, 'electronics', 1), 300)}
                  />
                </div>
                {error && error.fetchError === 1 && (
                  <div className="error-message">
                    <SecondaryDescription>
                      Oops, something went wrong.
                      <br />
                      Please try again later
                    </SecondaryDescription>
                  </div>
                )}
              </div>
            }
            rightContent={
              <OutputTextContainer>
                <OutputText
                  title="How much you could save in interest"
                  value={loadingIndex === 1 ? <DotsLoader /> : earlyCloseSaving}
                />
                <OutputText
                  title="How much earlier you could be mortgage-free"
                  value={loadingIndex === 1 ? <DotsLoader /> : estimatedTimeSaving}
                />
              </OutputTextContainer>
            }
            dropdownText={assumptionsText}
            handleClick={() => setIsDownloadModalOpen(true)}
            noPadding
            disclamerText={`
              The calculator is for illustrative purposes only. It does not provide a precise
              calculation of the time or interest you could save, but is intended to give you
              information and guidance about the potential savings. Please note the calculator
              does not factor in any charges for early repayments of the total mortgage balance
              and is based on a capital repayment mortgage.
            `}
          />
          <SliderSection sliderItems={sliderItems} customHeight={sliderHeight} />
          <div className='faq-section-title'>
            <FAQSection
              FAQItems={FAQItems}
              title={
                <>
                  Have a question about this feature?{" "}
                  {isDesktop || <br />}
                  Check out our FAQ’s
                </>
              }
            />
          </div>
          <DownloadAppSection />
          <ContactWidget
            handleClick={() => setIsDownloadModalOpen(true)}
            title={
              <>
                Download App <br /> for FREE
              </>
            }
            downloadIcon
          />
          <BasicModal
            isOpen={isDownloadModalOpen}
            closeHandler={() => setIsDownloadModalOpen(false)}
          >
            <DownloadAppModal />
          </BasicModal>
        </div>
      </div>
    </>
  );
}

export default ShopWithSprivePage;
